
import { Component, Vue } from 'vue-property-decorator';

import { mapState } from 'vuex';

import { mapValues, first } from 'lodash';

import { customFormatter, formatTime, formatStringTime, formatterDate } from '@/config/functions';

import jsPDF from 'jspdf';

import '@/config/Roboto-normal';

import autoTable from 'jspdf-autotable';

import * as Excel from 'exceljs';

import { downloadBlob } from 'download.js';

import { striposMixin } from '@/mixins/striposMixin.js';

import { colorTooltipsMixin } from '@/mixins/colorTooltipsMixin.js';

@Component({
   computed: {
      ...mapState({
         activities_colors: (s: any) => s.activities.colors,
         payment_colors: (s: any) => s.activities.paymentColors,
      }),
   },
   mixins: [striposMixin, colorTooltipsMixin],
})
export default class Booking extends Vue {
   form: any = null;

   activities_colors!: any[];

   payment_colors!: any[];

   customFormatter: any = customFormatter;

   formatTime: any = formatTime;

   formatStringTime: any = formatStringTime;

   formatterDate: any = formatterDate;

   default_colors: any = {
      o_group_color: '#FEFEFE',
      acс_status: '#ffffff',
      act_status: '#ffffff',
      transfer_status: '#ffffff',
      guide_status: '#ffffff',
      act_patment_status: '#ffffff',
      moto_overal_status: '#ffffff',
      acс_payment_status: '#ffffff',
      transfer_payment_status: '#ffffff',
      o_overal_status: '#ffffff',
   };

   filter!: any;

   async __download(type: string = 'pdf', $fields: any[], $data: any[], filename: string) {
      this.$store.commit('showLoading');

      switch (type) {
         case 'pdf':
            await this.__downloadPdf($fields, $data, filename + '.pdf');
            break;
         case 'xls':
            await this.__downloadXls($fields, $data, filename + '.xlsx');
      }
      setTimeout(() => this.$store.commit('hideLoading'), 500);
   }

   async __downloadPdf($fields: any[], $data: any[], filename: string) {
      const doc = await new jsPDF({
         orientation: 'landscape',
         format: 'a3',
      });

      const styles = { font: 'Roboto', fontStyle: 'normal', fontSize: 8 };
      await doc.setFont('Roboto', 'normal');
      await doc.setFontSize(8);

      const body = $data.map((field: any) => {
         return field.map((f: any) => ({
            content: f.value || '',
            styles,
            ...(/^#?[0-9A-F]{3,6}$/i.test(f.value.trim())
               ? {
                    content: typeof f.value == 'string' ? f.value : '',
                    styles: { textColor: '#' + f.value.replace('#', ''), fontSize: 8, font: 'PTSans-Regular' },
                 }
               : {}),
         }));
      });

      await doc.text('Generated on ' + this.$moment().format('DD.MM.YYYY HH:MM'), 252, 5);

      await autoTable(doc, {
         // @ts-ignore
         headStyles: {
            ...styles,
            fillColor: '#D5E9FA',
            textColor: '#000000',
            halign: 'center',
            valign: 'middle',
         },
         bodyStyles: {
            halign: 'center',
         },
         margin: { top: 7, left: 5, bottom: 5, right: 5 },
         head: [$fields.map(f => f.header)],
         body,
      });

      await doc.save(filename);
   }

   async __downloadXls($fields: any[], $data: any[], filename: string) {
      let workbook = await new Excel.Workbook();

      workbook.created = new Date();
      workbook.modified = new Date();
      workbook.lastPrinted = new Date();

      const worksheet = await workbook.addWorksheet('Publications');
      worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 2 }];

      worksheet.autoFilter = {
         from: 'A2',
         to: {
            row: $data.length + 2,
            column: $fields.length,
         },
      };

      worksheet.columns = $fields as Excel.Column[];

      await worksheet.addRows($data.map(d => d.map((dd: any) => dd.value)));

      await workbook.eachSheet(async worksheet => {
         await worksheet.eachRow(async (row, rowNumber) => {
            if (rowNumber > 1) {
               for (let i = 1; i <= row.cellCount; i++) {
                  let cell = row.getCell(i);
                  if (typeof cell.value == 'string') {
                     if (/^#?[0-9A-F]{3,6}$/i.test(cell.value.trim())) {
                        cell.fill = {
                           type: 'pattern',
                           pattern: 'solid',
                           fgColor: { argb: cell.value.trim().replace('#', '') },
                        };
                        cell.value = '';
                     } else if (this.$moment(cell.value.trim(), 'DD.MM.YYYY').isValid()) {
                        cell = {
                           ...cell,
                           type: 4,
                           value: new Date(this.$moment(cell.value.trim(), 'DD.MM.YYYY').format()),
                           style: {
                              numFmt: 'dd.mm.yyyy',
                           },
                        };
                     } else if (cell.value.trim() == 'null' || cell.value.trim() == '0') {
                        cell.value = '';
                     }
                  }
               }
            }

            await row.eachCell(cell => {
               cell.alignment = { wrapText: true };
            });
         });
      });

      let data = Object.keys($fields).map(f => '');
      data.pop();
      data.push('Generated on ' + this.$moment().format('DD.MM.YYYY HH:MM'));

      await worksheet.insertRow(1, data);

      await workbook.xlsx.writeBuffer({ useStyles: true }).then(data => {
         let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
         downloadBlob(filename, blob);
      });
   }

   beforeDestroy(): void {
      this.$store.commit('showLoading');
   }
}

import slugify from 'slugify';

export const striposMixin = {
   methods: {
      stripos($where, $what) {
         $where = this.prepareToSearch($where).toUpperCase();
         $what = this.prepareToSearch($what).toUpperCase();
         return $what === '' || $where.indexOf($what) >= 0;
      },
      prepareToSearch(str) {
         return !['number', 'string'].includes(typeof str) ? '' : typeof str === 'number' ? str.toString() : slugify(str);
      },
   },
};
